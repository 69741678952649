import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  Box,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';

const MotionContainer = motion(Container);
const MotionGrid = motion(Grid);
const MotionCard = motion(Card);

const projects = [
  {
    title: 'OutQuotes',
    description: 'A modern web application platform for managing quotes, orders, and customer relationships.',
    tags: ['Flask', 'Python', 'PostgreSQL', 'Docker', 'CI/CD', 'Pipeline', 'Azure', 'React', 'TailwindCSS'],
    demo: 'https://templar.app.outquotes.com',
    image: '/images/outquotes.jpg',
    color: '#4a9eff',
  },
  {
    title: 'ZeroToil Development',
    description: 'Affordable high quality game utility mods for SpigotAPI.',
    tags: ['Java', 'Minecraft', 'SQL', 'YAML', 'Documentation'],
    github: 'https://github.com/zerotoil',
    demo: 'https://dev.zerotoil.net',
    image: '/images/zerotoil.png',
    color: '#00ffa3',
  },
  {
    title: 'Production Panel',
    description: 'Used at Templar Screens to manage each step in the screen building process.',
    tags: ['React', 'NodeJS', 'TypeScript', 'MongoDB', 'CI/CD', 'Pipeline', 'Azure'],
    image: '/images/production_panel.png',
    color: '#00ffa3',
  },
  {
    title: 'Husky Huddle',
    description: 'A social platform for UW students to connect, collaborate, and share resources.',
    tags: ['JavaScript', 'React', 'NodeJS', 'SQL', 'API'],
    github: 'https://github.com/Kihsomray/huskyhuddle.com',
    demo: 'http://huskyhuddle.com',
    image: '/images/husky_huddle.png',
    color: '#ff3366',
  },
  {
    title: 'Grid Master',
    description: 'A web application for managing daily tasks for the OutQuotes team.',
    tags: ['React', 'NodeJS', 'TypeScript', 'MongoDB', 'CI/CD', 'Pipeline', 'Azure'],
    image: '/images/grid_master.png',
    color: '#00ffa3',
  },
  {
    title: 'Inventory Management',
    description: 'An Inventory Management System (IMS) for a business that sells an assortment of goods.',
    tags: ['C#', 'SQL', 'MVC', '.NET', 'ASP'],
    github: 'https://github.com/Kihsomray/InventoryManagement',
    image: '/images/inventory_management.png',
    color: '#4a9eff',
  },
  {
    title: 'My Wedding Website',
    description: 'A website for RSVP and registry information for my wedding built with React and NodeJS.',
    tags: ['React', 'NodeJS', 'Express', 'CI/CD', 'Pipeline'],
    demo: 'https://rsvp.yarmoshik.com',
    image: '/images/wedding_website.png',
    color: '#4a9eff',
  },
  {
    title: 'SpreadSheet ADT',
    description: 'Dynamic spreadsheet engine with formula assignment and computation written in Java.',
    tags: ['Java', 'Swing'],
    github: 'https://github.com/Kihsomray/Spreadsheet-ADT',
    image: '/images/spreadsheet_adt.png',
    color: '#ff9d00',
  },
  {
    title: 'Dissonant World',
    description: 'A 2D RPG with free-to-play exploration, monster battles, and strategic resource use.',
    tags: ['JavaScript', 'HTML', 'CSS'],
    github: 'https://github.com/Kihsomray/dissonant.world',
    demo: 'https://dissonant.world',
    image: '/images/dissonant_world.png',
    color: '#9b4dff',
  },
  {
    title: 'Dungeon Dash Saga',
    description: 'A maze game with varying difficulties and timed runs.',
    tags: ['JavaScript', 'GitHub Pages'],
    github: 'https://github.com/Kihsomray/DungeonDash-Saga',
    demo: 'https://dungeondash.net',
    image: '/images/dungeon_dash_saga.png',
    color: '#ff4d4d',
  },
  {
    title: 'Dungeon Dash',
    description: 'A console turn-based dungeon exploration game utilizing best practices written in Java.',
    tags: ['Java', 'JavaFX'],
    github: 'https://github.com/Kihsomray/DungeonDash',
    image: '/images/dungeon_dash.png',
    color: '#ff8833',
  },
  {
    title: 'Custom Websites',
    description: 'Custom static websites built for an array of clients.',
    tags: ['HTML', 'CSS', 'JavaScript', 'Jekyll'],
    demo: 'https://autopol.org',
    image: '/images/custom_websites.png',
    color: '#4dff88',
  },
  {
    title: 'Youth PNW',
    description: 'Cultivating Spiritual Growth, Community, and Unity within the Body of Christ.',
    tags: ['Squarespace', 'Google Maps', 'Website'],
    demo: 'https://www.youthpnw.com',
    image: '/images/youth_pnw.png',
    color: '#ff8833',
  },
  {
    title: 'Simply Bible',
    description: 'A Discord bot simplifying scripture searches and verse retrieval.',
    tags: ['Python', 'Discord', 'JSON'],
    github: 'https://github.com/Kihsomray/SimplyBible',
    demo: 'http://simply-bible.com',
    image: '/images/simply_bible.png',
    color: '#4dff88',
  },
  {
    title: 'Bible Psalm Offsets',
    description: 'ProPresenter Library for the book of Psalms that has fixed offsets, with a helper coded in Python.',
    tags: ['Python', 'ProPresenter'],
    github: 'https://github.com/Kihsomray/Psalm-Offsets-EN-RU',
    image: '/images/propresenter_offsets.png',
    color: '#00a5ff',
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

export default function Projects() {
  const theme = useTheme();

  return (
    <MotionContainer
      maxWidth="lg"
      sx={{ mt: { xs: 8, md: 12 }, mb: 8 }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={cardVariants}>
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.text.primary,
            mb: 4,
            textAlign: 'center',
            background: 'linear-gradient(45deg, #00ffa3, #64ffda)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Featured Projects
        </Typography>
      </motion.div>

      <MotionGrid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <MotionCard
              variants={cardVariants}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '4px',
                  background: project.color,
                  borderRadius: '4px 4px 0 0',
                },
                '&:hover': {
                  transform: 'translateY(-8px)',
                  transition: 'transform 0.3s ease',
                  '& .project-title': {
                    color: project.color,
                  },
                  '& .project-image': {
                    transform: 'scale(1.1)',
                  },
                },
              }}
            >
              <CardMedia
                component="div"
                className="project-image"
                sx={{
                  height: 200,
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  transition: 'transform 0.3s ease',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `linear-gradient(to bottom, transparent 0%, rgba(10, 15, 30, 0.8) 100%)`,
                  },
                }}
                image={project.image}
                title={project.title}
              />
              <CardContent sx={{ flexGrow: 1, p: 3 }}>
                <Typography
                  className="project-title"
                  gutterBottom
                  variant="h5"
                  component="h2"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    transition: 'color 0.3s ease',
                  }}
                >
                  {project.title}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.text.secondary,
                    mb: 2,
                    fontSize: '1rem',
                    lineHeight: 1.6,
                  }}
                >
                  {project.description}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  {project.tags.map((tag, tagIndex) => (
                    <Typography
                      key={tagIndex}
                      variant="caption"
                      sx={{
                        color: project.color,
                        backgroundColor: `${project.color}15`,
                        padding: '6px 12px',
                        borderRadius: '16px',
                        fontSize: '0.85rem',
                        fontWeight: 500,
                      }}
                    >
                      {tag}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
              <CardActions sx={{ p: 3, pt: 0 }}>
                {project.github && (
                  <Button
                    size="large"
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<GitHubIcon />}
                    sx={{
                      color: theme.palette.text.primary,
                      '&:hover': {
                        color: project.color,
                      },
                    }}
                  >
                    View Code
                  </Button>
                )}
                {project.demo && (
                  <Button
                    size="large"
                    href={project.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<LaunchIcon />}
                    sx={{
                      color: theme.palette.text.primary,
                      '&:hover': {
                        color: project.color,
                      },
                    }}
                  >
                    Live Demo
                  </Button>
                )}
              </CardActions>
            </MotionCard>
          </Grid>
        ))}
      </MotionGrid>
    </MotionContainer>
  );
} 