import React from 'react';
import { Container, Typography, Box, Grid, LinearProgress, Button, Paper, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import DownloadIcon from '@mui/icons-material/Download';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

const MotionBox = motion(Box);
const MotionPaper = motion(Paper);

const skills = [
  {
    category: 'Core Languages',
    items: [
      { name: 'Java', level: 90, color: '#f89820' },
      { name: 'JavaScript/TypeScript', level: 85, color: '#f7df1e' },
      { name: 'HTML/CSS', level: 95, color: '#e34c26' },
      { name: 'C#', level: 55, color: '#68217a' },
      { name: 'Python', level: 45, color: '#3776ab' },
    ]
  },
  {
    category: 'Frameworks',
    items: [
      { name: 'Node.js/React', level: 85, color: '#61dafb' },
      { name: 'Spigot API', level: 90, color: '#ff5733' },
      { name: 'RESTful APIs', level: 65, color: '#0081cb' },
      { name: 'SQL/ER Diagrams', level: 70, color: '#00758f' },
    ]
  },
  {
    category: 'Development Tools',
    items: [
      { name: 'Git', level: 85, color: '#f05032' },
      { name: 'JetBrains IDEs', level: 85, color: '#000000' },
      { name: 'FileZilla', level: 65, color: '#BF0000' },
      { name: 'VirtualBox', level: 65, color: '#183A61' },
    ]
  },
  {
    category: 'Other Skills',
    items: [
      { name: 'Linux', level: 40, color: '#fcc624' },
      { name: 'R', level: 25, color: '#276DC3' },
      { name: 'Kotlin', level: 25, color: '#B125EA' },
      { name: 'Erlang', level: 25, color: '#A90533' },
      { name: 'C', level: 25, color: '#555555' },
    ]
  }
];

const timelineItems = [
  {
    year: '2024 - Present',
    title: 'Software Engineer',
    company: 'Templar Screens',
    description: 'Improving website functionality, optimizing internal processes, and developing a full-stack software solution to enhance operational efficiency. Working on designing carbon fiber parts using CAD and 3D printing for product development.',
    icon: <WorkIcon />,
  },
  {
    year: '2022 - 2024',
    title: 'B.S. Computer Science',
    company: 'University of Washington',
    description: 'Completed Bachelor\'s in CS with achievements in coding competitions, including a unique problem solve and second-place finish. Worked on various projects deepening core CS concepts.',
    icon: <SchoolIcon />,
  },
  {
    year: '2021 - 2024',
    title: 'Founding Software Engineer',
    company: 'ZeroToil',
    description: 'Led development team producing high-quality code with 30,000+ purchases & downloads. Grew support community to 850+ members and managed documentation site using Markdown, Jekyll, and GitHub Pages.',
    icon: <WorkIcon />,
  },
  {
    year: '2020 - 2022',
    title: 'Associate Degree',
    company: 'Green River College',
    description: 'Earned Associate Degree through Running Start program, managing full-time college coursework alongside high school. Graduated with 4.0 GPA, making Honors and Dean\'s List.',
    icon: <SchoolIcon />,
  },
  {
    year: '2018 - 2022',
    title: 'High School Graduate',
    company: 'Kent Lake High School',
    description: 'Graduated as valedictorian with 4.0 GPA, earning honors, Seal of Biliteracy, and consistent placement on Honors and Dean\'s Lists.',
    icon: <SchoolIcon />,
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

export default function Skills() {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 8, md: 12 }, mb: 8 }}>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Typography
          variant="h2"
          sx={{
            color: theme.palette.text.primary,
            mb: 6,
            textAlign: 'center',
            background: 'linear-gradient(45deg, #4D9FFF, #4DFFB5)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Technical Skills
        </Typography>

        <Grid container spacing={4}>
          {skills.map((category, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.primary,
                  mb: 2,
                  fontWeight: 600,
                  fontSize: '1.1rem',
                }}
              >
                {category.category}
              </Typography>
              {category.items.map((skill, skillIndex) => (
                <MotionBox
                  key={skillIndex}
                  variants={itemVariants}
                  sx={{ mb: 2 }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography 
                      color="text.secondary"
                      sx={{ fontSize: '0.9rem' }}
                    >
                      {skill.name}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={skill.level}
                    sx={{
                      height: 6,
                      borderRadius: 3,
                      backgroundColor: 'rgba(255,255,255,0.1)',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 3,
                        background: `linear-gradient(90deg, ${skill.color}, ${skill.color}dd)`,
                      },
                    }}
                  />
                </MotionBox>
              ))}
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 12, textAlign: 'center', mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.text.primary,
              mb: 3,
              background: 'linear-gradient(45deg, #00ffa3, #64ffda)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Professional Journey
          </Typography>
          
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            href="/resume/Michael_Yarmoshik_Resume.pdf"
            target="_blank"
            sx={{
              backgroundColor: 'rgba(77, 255, 181, 0.1)',
              color: '#00ffa3',
              padding: '12px 24px',
              '&:hover': {
                backgroundColor: 'rgba(77, 255, 181, 0.2)',
              },
            }}
          >
            Download Resume
          </Button>
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              left: { xs: '20px', md: '50%' },
              transform: { xs: 'none', md: 'translateX(-50%)' },
              top: 0,
              bottom: 0,
              width: '2px',
              background: 'linear-gradient(to bottom, #00ffa3, #4D9FFF)',
            }}
          />

          {timelineItems.map((item, index) => (
            <MotionPaper
              key={index}
              variants={itemVariants}
              sx={{
                p: 3,
                mb: 4,
                ml: { xs: '40px', md: index % 2 ? '50%' : '0' },
                mr: { xs: 0, md: index % 2 ? '0' : '50%' },
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: { xs: '-32px', md: index % 2 ? '-12px' : 'auto' },
                  right: { xs: 'auto', md: index % 2 ? 'auto' : '-12px' },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  background: 'linear-gradient(45deg, #00ffa3, #4D9FFF)',
                  zIndex: 1,
                },
              }}
            >
              <Box sx={{ 
                position: 'absolute',
                left: { xs: '-46px', md: index % 2 ? '-26px' : 'auto' },
                right: { xs: 'auto', md: index % 2 ? 'auto' : '-26px' },
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#00ffa3',
                zIndex: 2,
              }}>
                {item.icon}
              </Box>

              <Typography
                variant="h6"
                sx={{
                  color: '#00ffa3',
                  mb: 1,
                  fontSize: '1rem',
                  fontFamily: 'Space Mono, monospace',
                }}
              >
                {item.year}
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.text.primary,
                  mb: 1,
                  fontWeight: 600,
                }}
              >
                {item.title}
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.text.secondary,
                  mb: 2,
                }}
              >
                {item.company}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                {item.description}
              </Typography>
            </MotionPaper>
          ))}
        </Box>
      </motion.div>
    </Container>
  );
} 